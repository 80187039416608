import axios from "@/utils/axios"
import urls from "@/utils/urls"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"

export default {
  state: {
    // global
    courseChoices: [],
    batchChoices: [],
    listLoading: false,
    statusList: [
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "Accepted",
        value: "accepted",
      },
      // {
      //   text: "Passed",
      //   value: "passed",
      // },
      // {
      //   text: "Failed",
      //   value: "failed",
      // },
      {
        text: "Rejected",
        value: "rejected",
      },
    ],
    // Assignment
    byAssignment: {
      searchAssignment: "",
      selectedCourse: "",
      selectedBatch: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
    // View Assignment
    viewAssignment: {
      searchStudent: "",
      selectedBatch: "",
      selectedStatus: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
    // Batch
    byBatch: {
      searchBatch: "",
      selectedCourse: "",
      selectedBatch: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
    // View Batch
    viewBatch: {
      searchStudent: "",
      selectedCourse: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
    // Student
    byStudent: {
      searchStudent: "",
      selectedCourse: "",
      selectedBatch: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
    // View Student
    viewStudent: {
      searchAssignment: "",
      selectedCourse: "",
      selectedStatus: "",
      sort: {
        fields: [],
        desc: [],
      },
      page: 1,
      data: {
        data: [],
        total: 0,
      },
    },
  },
  getters: {
    // global
    listLoading: state => state.listLoading,
    batchChoices: state => state.batchChoices,
    courseChoices: state => state.courseChoices,
    statusList: state => state.statusList,

    // Assignments
    assignments: state => state.byAssignment,
    // View Assignment
    viewAssignments: state => state.viewAssignment,
    // Batch
    batches: state => state.byBatch,
    // View Batch
    viewBatches: state => state.viewBatch,
    // Student
    students: state => state.byStudent,
    // View Student
    viewStudents: state => state.viewStudent,
  },
  actions: {
    // global
    async getCourseBatchChoices({ commit }) {
      try {
        const res = await axios.get(urls.courseBatchChoices)
        const newCourses = res.data.data.courses
        const newBatches = res.data.data.centres
        commit("SET_BATCH_CHOICE", newBatches)
        commit("SET_COURSE_CHOICE", newCourses)
      } catch (error) {
        console.error(error)
      }
    },
    //
    // Assignment
    async getAssignments({ commit, state }) {
      commit("LIST_LOADING", true)
      try {
        const {
          searchAssignment,
          selectedCourse,
          // selectedBatch,
          sort,
          page,
        } = state.byAssignment

        const res = await axios.get(urls.assignments.list, {
          params: {
            search: getValueOrNull(searchAssignment),
            courses: getValueOrNull(selectedCourse),
            // batch: getValueOrNull(selectedBatch),
            ordering: getSortableFieldsArray(sort.fields, sort.desc),
            page: page,
          },
        })
        commit("SET_ASSIGNMENT", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
    //
    // View Assignment
    async getViewAssignments({ commit, state }, assignmentId) {
      commit("LIST_LOADING", true)
      try {
        const {
          searchStudent,
          selectedStatus,
          sort,
          page,
        } = state.viewAssignment
        const { selectedBatch } = state.byAssignment
        const res = await axios.get(
          urls.students.viewstudentList(assignmentId),
          {
            params: {
              search: getValueOrNull(searchStudent),
              status: getValueOrNull(selectedStatus),
              batch: getValueOrNull(selectedBatch),
              ordering: getSortableFieldsArray(sort.fields, sort.desc),
              page: page,
              fields: "status",
            },
          }
        )
        commit("SET_VIEW_ASSIGNMENT", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
    // Batch
    async getBatches({ commit, state }) {
      commit("LIST_LOADING", true)
      try {
        const {
          searchBatch,
          selectedCourse,
          selectedBatch,
          sort,
          page,
        } = state.byBatch
        const res = await axios.get(urls.batches.getBatches, {
          params: {
            search: getValueOrNull(searchBatch),
            course: getValueOrNull(selectedCourse),
            batch: getValueOrNull(selectedBatch),
            ordering: getSortableFieldsArray(sort.fields, sort.desc),
            page: page,
          },
        })
        commit("SET_BATCH", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
    //
    // View Batches
    async getViewBatches({ commit, state }, batchId) {
      commit("LIST_LOADING", true)
      try {
        const { searchStudent, selectedCourse, sort, page } = state.viewBatch
        const res = await axios.get(urls.batches.viewBatchList(batchId), {
          params: {
            search: getValueOrNull(searchStudent),
            course: getValueOrNull(selectedCourse),
            ordering: getSortableFieldsArray(sort.fields, sort.desc),
            page: page,
          },
        })
        commit("SET_VIEW_BATCH", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
    // Student
    async getStudents({ commit, state }) {
      commit("LIST_LOADING", true)
      try {
        const {
          searchStudent,
          selectedCourse,
          // selectedBatch,
          sort,
          page,
        } = state.byStudent
        const res = await axios.get(urls.students.getStudents, {
          params: {
            search: getValueOrNull(searchStudent),
            courses: getValueOrNull(selectedCourse),
            // batch: getValueOrNull(selectedBatch),
            ordering: getSortableFieldsArray(sort.fields, sort.desc),
            page: page,
          },
        })
        commit("SET_STUDENT", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
    // View Student
    async getViewStudents({ commit, state }, studentId) {
      commit("LIST_LOADING", true)
      try {
        const {
          searchAssignment,
          selectedCourse,
          sort,
          page,
          selectedStatus,
        } = state.viewStudent
        const res = await axios.get(urls.students.listAssignments(studentId), {
          params: {
            fields: ["status"],
            search: getValueOrNull(searchAssignment),
            status: getValueOrNull(selectedStatus),
            course: getValueOrNull(selectedCourse),
            ordering: getSortableFieldsArray(sort.fields, sort.desc),
            page: page,
          },
        })
        commit("SET_VIEW_STUDENT", res.data)
      } catch (error) {
        console.error(error)
      } finally {
        commit("LIST_LOADING", false)
      }
    },
  },
  mutations: {
    // global
    SET_COURSE_CHOICE(state, payload) {
      state.courseChoices = payload
    },
    SET_BATCH_CHOICE(state, payload) {
      state.batchChoices = payload
    },
    LIST_LOADING(state, payload) {
      state.listLoading = payload
    },
    SET_TOTAL_Data(state, payload) {
      state.totalData = payload
    },
    // Assignment
    SET_ASSIGNMENT(state, payload) {
      state.byAssignment.data = payload
    },
    ADD_SEARCH_ASSIGNMENT(state, payload) {
      state.byAssignment.searchAssignment = payload
    },
    SET_ASSIGNMENT_SORTABLE_FIELDS(state, payload) {
      state.byAssignment.sort = payload
    },
    SET_ASSIGNMENT_PAGE(state, { page }) {
      state.byAssignment.page = page
    },
    ADD_ASSIGNMENT_SELECTED_COURSE(state, payload) {
      state.byAssignment.selectedCourse = payload
    },
    ADD_ASSIGNMENT_SELECTED_BATCH(state, payload) {
      state.byAssignment.selectedBatch = payload
    },
    // View Assignment
    SET_VIEW_ASSIGNMENT(state, payload) {
      state.viewAssignment.data = payload
    },
    ADD_VIEW_SEARCH_ASSIGNMENT(state, payload) {
      state.viewAssignment.searchStudent = payload
    },
    SET_VIEW_ASSIGNMENT_SORTABLE_FIELDS(state, payload) {
      state.viewAssignment.sort = payload
    },
    SET_VIEW_ASSIGNMENT_PAGE(state, { page }) {
      state.viewAssignment.page = page
    },
    ADD_VIEW_ASSIGNMENT_STATUS(state, payload) {
      state.viewAssignment.selectedStatus = payload
    },
    ADD_VIEW_ASSIGNMENT_SELECTED_BATCH(state, payload) {
      state.viewAssignment.selectedBatch = payload
    },
    // Batch
    SET_BATCH(state, payload) {
      state.byBatch.data = payload
    },
    ADD_SEARCH_BATCH(state, payload) {
      state.byBatch.searchBatch = payload
    },
    SET_BATCH_SORTABLE_FIELDS(state, payload) {
      state.byBatch.sort = payload
    },
    SET_BATCH_PAGE(state, { page }) {
      state.byBatch.page = page
    },
    ADD_BATCH_SELECTED_COURSE(state, payload) {
      state.byBatch.selectedCourse = payload
    },
    ADD_BATCH_SELECTED_BATCH(state, payload) {
      state.byBatch.selectedBatch = payload
    },
    // View Batch
    SET_VIEW_BATCH(state, payload) {
      state.viewBatch.data = payload
    },
    ADD_VIEW_SEARCH_BATCH(state, payload) {
      state.viewBatch.searchStudent = payload
    },
    SET_VIEW_BATCH_SORTABLE_FIELDS(state, payload) {
      state.viewBatch.sort = payload
    },
    SET_VIEW_BATCH_PAGE(state, { page }) {
      state.viewBatch.page = page
    },
    ADD_VIEW_BATCH_SELECTED_COURSE(state, payload) {
      state.viewBatch.selectedCourse = payload
    },

    // Student
    SET_STUDENT(state, payload) {
      state.byStudent.data = payload
    },
    ADD_SEARCH_STUDENT(state, payload) {
      state.byStudent.searchStudent = payload
    },
    SET_STUDENT_SORTABLE_FIELDS(state, payload) {
      state.byStudent.sort = payload
    },
    SET_STUDENT_PAGE(state, { page }) {
      state.byStudent.page = page
    },
    ADD_STUDENT_SELECTED_COURSE(state, payload) {
      state.byStudent.selectedCourse = payload
    },
    ADD_STUDENT_SELECTED_BATCH(state, payload) {
      state.byStudent.selectedBatch = payload
    },
    // View Student
    SET_VIEW_STUDENT(state, payload) {
      state.viewStudent.data = payload
    },
    ADD_VIEW_SEARCH_STUDENT(state, payload) {
      state.viewStudent.searchAssignment = payload
    },
    SET_VIEW_STUDENT_SORTABLE_FIELDS(state, payload) {
      state.viewStudent.sort = payload
    },
    SET_VIEW_STUDENT_PAGE(state, { page }) {
      state.viewStudent.page = page
    },
    ADD_VIEW_STUDENT_SELECTED_COURSE(state, payload) {
      state.viewStudent.selectedCourse = payload
    },
    ADD_VIEW_STUDENT_STATUS(state, payload) {
      state.viewStudent.selectedStatus = payload
    },
  },
}
